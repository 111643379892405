import * as React from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import { useTheme } from '@mui/material';
import { Tiktok } from 'styled-icons/boxicons-logos';
import Logo from '../../images/logo.svg';
import LogoDark from '../../images/logo-dark.svg';
import { navigate } from 'gatsby';

const Footer = () => {
    const theme = useTheme();

    return(
        <footer>
            <Paper
                sx={{
                    backgroundColor: theme.palette.background.default,
                    pt: 4,
                    pb: 8,
                    zIndex: 200,
                }}
            >
                <Container spacing={ 2 }>
                    <Grid container>
                        <Grid xs={ 12 } sx={{ justifyContent: 'center', display: 'flex' }} item>
                            <img
                                style={{
                                    width: 60
                                }}
                                alt="logo"
                                src={ theme?.palette?.mode === 'dark' ? Logo : LogoDark }
                            />
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Stack justifyContent="center" direction="row">
                                <IconButton
                                    onClick={ () => window.open('https://facebook.com/ohmyzoneoficial','_blank') }
                                >
                                    <Facebook/>
                                </IconButton>
                                <IconButton
                                    onClick={ () => window.open('https://instagram.com/ohmyzoneoficial','_blank') }
                                >
                                    <Instagram/>
                                </IconButton>
                                <IconButton
                            
                                    aria-label="tiktok"
                                    onClick={ () => window.open( 'https://www.tiktok.com/@ohmyzoneoficial', '_blank' ) }
                                >
                                    <Tiktok size={24}/>
                                </IconButton>
                            </Stack>
                        </Grid>
                        <Grid item xs={ 12 } sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography
                                color="text.secondary"
                                variant="caption"
                                align="center"
                                onClick={ () => navigate( '/legal/terms' ) }
                                sx={{ textDecoration: 'underline', mr: 2, cursor: 'pointer' }}
                            >
                               Términos y condiciones
                            </Typography>
                            <Typography
                                color="text.secondary"
                            >
                                |
                            </Typography>
                            <Typography
                                color="text.secondary"
                                variant="caption"
                                align="center"
                                onClick={ () => navigate( '/legal/privacy' ) }
                                sx={{ textDecoration: 'underline', ml: 2, cursor: 'pointer' }}
                            >
                               Aviso de privacidad
                            </Typography>
                        </Grid>
                        <Grid item xs={ 12 } sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography
                                color="text.secondary"
                                variant="caption"
                                align="center"
                            >
                                © OhMyZone! - { new Date().getFullYear() } 
                            </Typography>
                        </Grid>
                    </Grid>

                </Container>
            </Paper>

        </footer>
    )
}

export default Footer;