import * as React from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import { motion } from "framer-motion";
// @ts-ignore
import Logo from '../images/logo.svg';
import useTheme from '@mui/material/styles/useTheme';
import StyledButton from '../styled/StyledButton';
import { Link } from "gatsby";

const Header = () => {
    const theme = useTheme();

    return(
        <>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: theme.palette.primary.main
                }}
            >
                <Toolbar sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Link
                        to="/"
                    >

                        <motion.img
                            initial={{
                                opacity: 0,
                                x: -10,
                            }}
                            animate={{
                                x:0,
                                opacity: 1,
                                transition: {
                                    delay: 0.5
                                }
                            }}
                            style={{
                                height: 40,
                                marginBottom: 0
                            }}
                            alt="logo"
                            src={Logo}
                        />
                    </Link>

                    <Stack direction="row" spacing={ 2 }>
                        <StyledButton
                            sx={{
                                color: theme.palette.text.primary,
                                border: '1px solid white'
                            }}
                            onClick={ () => window.open( process.env.GATSBY_APP_HOST, '_blank' ) }
                            variant="outlined"
                        >
                            Iniciar Sesión
                        </StyledButton>
                        <StyledButton
                            color="secondary"
                            variant="contained"
                            onClick={ () => window.open( process.env.GATSBY_APP_HOST + 'register', '_blank' ) }
                        >
                            ¡Regístrate!
                        </StyledButton>
                    </Stack>
                </Toolbar>
            </AppBar>
        </>

    );
}

export default Header;